import {authHeaders} from "../http/AuthHeaders";
const API_URL = process.env.REACT_APP_BACKEND_API_URL;
class FOUserService {
    static async getUser() {
        try {
            const response = await fetch(`${API_URL}fouser`, {
                method: 'GET',
                headers: authHeaders()
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    }
    static async addUser(inputData: {email: string, full_name: string, nickname: string, tg_nick: string}) {
        try {
            const response = await fetch(`${API_URL}fouser`, {
                method: 'POST',
                headers: authHeaders(),
                body: JSON.stringify({
                    email: inputData.email,
                    full_name: inputData.full_name,
                    nickname: inputData.nickname,
                    tg_nick: inputData.tg_nick
                })
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    }
    static async editUser(userId: string | undefined, inputData: {email: string, full_name: string, nickname: string, tg_nick: string}) {
        try {
            const response = await fetch(`${API_URL}fouser`, {
                method: 'PUT',
                headers: authHeaders(),
                body: JSON.stringify({
                    oid: userId,
                    email: inputData.email,
                    full_name: inputData.full_name,
                    nickname: inputData.nickname,
                    tg_nick: inputData.tg_nick
                })
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    }
}

export default FOUserService