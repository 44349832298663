import React, { useEffect, useState } from 'react';
import './Catalog.css'
import '../Pagination/Pagination.css'
import TracksService from "../../../services/TracksService";
import ReleaseService from "../../../services/ReleaseService";
import Search from "./Search/Search";
import StickyBox from "react-sticky-box";
import Release from "./Release/Release";
import ReactPaginate from "react-paginate";
import Pagination from "../Pagination/Pagination";
import AddConsumptionForm from "./AddConsumptionForm/AddConsumptionForm";
import Popup from "../../Popup/Popup";
import track from "./Release/Release";
import AddReleaseForm from "./AddReleaseForm/AddReleaseForm";
export interface ITrack {
    upc: string;
    isrc: string[];
    broma16id: string[]; // Assuming this should be an array of strings
    label: string;
    distributor?: string; // Optional as it can be empty
    article?: string; // Optional as it can be empty or null
    license_end_date?: string | null; // Optional and can be null
    artist: string;
    genre: string;
    key: string;
    title: string;
    remix: string;
    album: string;
    length: string;
    release_date: string;
    licensors: string[];
    shares: number[];
    label_share: string;
    expense_calculation_type: 'common' | 'individual';
}

export interface IRelease {
    expense_calculation_type: number;
    upc: string,
    musictracks: Array<ITrack>
}
const Catalog = () => {
    const [isAddInvestOpen, setIsAddInvestOpen] = useState<boolean>(false)
    const [isReleaseOrTrack, setIsReleaseOrTrack] = useState<string>('')
    const [releases, setReleases] = useState<Array<string>>([])
    const [currentProductForAddInvest, setCurrentProductForAddInvest] = useState<string>()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [totalItems, setTotalItems] = useState<number>(0)
    const [isAddReleaseOpen, setIsAddReleaseOpen] = useState<boolean>(false)
    const [musicTrack, setMusicTrack] = useState<any | undefined>(undefined);
    const [editMode, setEditMode] = useState(false);
    const [searchText, setSearchText] = useState<string>("")
    const itemsPerPage: number = 15;
    const [triggerCounter, setTriggerCounter] = useState(0);

    const handleExternalEvent = () => {
        // increment counterRef to trigger the effect to run again
        setTriggerCounter(triggerCounter + 1);
    }

    const openAddInvestFunc = (isReleaseOrTrack: string, releaseName: string, releaseUpc: string): any => {
        setIsAddInvestOpen(true)
        setIsReleaseOrTrack(isReleaseOrTrack)
        setCurrentProductForAddInvest(releaseName)
    }
    useEffect(() => {
        ReleaseService.getReleases(currentPage, searchText).then(res => {
            setReleases(res.releases)
            setTotalItems(res.total_releases_count)
        })
    }, [currentPage, triggerCounter])

    useEffect(() => {
        const timer = setTimeout(() => {
            ReleaseService.getReleases(currentPage, searchText).then(res => {
                setReleases(res.releases)
                setTotalItems(res.total_releases_count)
            })
        }, 400);
        return () => clearTimeout(timer);
    }, [searchText])

    const openFormForEdit = (track: any) => {
        console.log(track)
        setMusicTrack(track);
        setEditMode(true);
        setIsAddReleaseOpen(true);
    };

    const openFormForAdd = () => {
        setMusicTrack(undefined);
        setEditMode(false);
        setIsAddReleaseOpen(true);
    };

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    return (
        <div className={"catalog"}>
            <div className={"add-release__popup-wrapper"}>
                <Popup isActive={isAddReleaseOpen} setActive={setIsAddReleaseOpen} >
                    <div className={"add-release__wrapper"}>
                        <AddReleaseForm
                            setActive={setIsAddReleaseOpen}
                            handleExternalEvent={handleExternalEvent}
                            musicTrack={editMode ? musicTrack : undefined}
                        />
                    </div>
                </Popup>
            </div>
            <div className={"catalog__wrapper"}>
                <div className={"catalog__left-side"}>
                    <div className={"catalog__top"}>
                        <Search searchTextSetter={setSearchText} />
                        <div className={"add-track__button__inner"}>
                            <button className={"add-track__button"} onClick={openFormForAdd}>ДОБАВИТЬ РЕЛИЗ</button>
                        </div>
                    </div>
                    <div className={"pagination__wrapper"}>
                        <Pagination currentPage={currentPage} paginate={paginate} itemsPerPage={itemsPerPage} totalItems={totalItems} />
                    </div>
                    <div className={"catalog__track-list"}>
                        {
                            releases.map((item: any, key: number) => {
                                return <Release release={item} setIsAddInvestOpen={openAddInvestFunc} key={key} handleExternalEvent={handleExternalEvent} openFormForEdit={openFormForEdit}/>
                            })
                        }
                    </div>
                    <div className={"pagination__wrapper"}>
                        <Pagination currentPage={currentPage} paginate={paginate} itemsPerPage={itemsPerPage} totalItems={totalItems} />
                    </div>
                </div>
                {/* <div className={"catalog__right-side"}>
                    <StickyBox offsetTop={20}>
                        <div className={"add-invest-desktop"}>
                            {
                                isAddInvestOpen && <AddConsumptionForm type={isReleaseOrTrack} product={currentProductForAddInvest} />
                            }
                        </div>
                    </StickyBox>
                    <div className={"add-invest-mobile"}>
                        <Popup isActive={isAddInvestOpen} setActive={setIsAddInvestOpen} >
                            <AddConsumptionForm type={isReleaseOrTrack} product={currentProductForAddInvest} />
                        </Popup>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Catalog;