import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import MoneyFlowService from '../../../../services/MoneyFlowService';
import './AddSpendingForm.css'

type AddSpendingFormValues = {
    upc: string;
    artist: string;
    album: string;
    category: string;
    description: string;
    amount: number;
    date_added: Date;
}

const AddSpendingForm = (props: { setIsActive: (value: boolean) => void, handleExternalEvent: Function }) => {
    const { setIsActive, handleExternalEvent } = props
    const { reset, register, handleSubmit, formState: { errors } } = useForm<AddSpendingFormValues>();

    const onSubmit: SubmitHandler<AddSpendingFormValues> = data => {
        MoneyFlowService.addSpending(data.upc, data.artist, data.album, data.category, data.description, data.amount, data.date_added).then(res => {
            handleExternalEvent();
            reset();
        })

    };
    return (
        <div className={"add-spending__form__container"}>
            <h3 className={"add-spending__form__title"}>💸 Добавить Расход</h3>
            <form className={"add-spending__form"} onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label className={"add-spending__form-label"}>UPC:</label>
                    <input className={"add-spending__form-input"} {...register("upc")} />
                </div>
                <div className={"add-spending__form-inputs"}>                    
                    <div className={"add-spending__form-inputs-left"}>
                        <label className={"add-spending__form-label"}>Артист:</label>
                        <input className={"add-spending__form-input"} {...register("artist")} />
                        <label className={"add-spending__form-label"}>Категория:</label>
                        <input className={"add-spending__form-input"} {...register("category")} />
                        <label className={"add-spending__form-label"}>Сумма в рублях:</label>
                        <input type="number" className={"add-spending__form-input"} {...register("amount")} />
                    </div>
                    <div>
                        <label className={"add-spending__form-label"}>Название релиза:</label>
                        <input className={"add-spending__form-input"} {...register("album")} />
                        <label className={"add-spending__form-label"}>Статья расхода:</label>
                        <input className={"add-spending__form-input"} {...register("description")} />
                        <label className={"add-spending__form-label"}>Дата добавления:</label>
                        <input type="date" className={"add-spending__form-input"} {...register("date_added")} />
                    </div>
                </div>
                <div className={"add--spending__form-btn__wrapper"}>
                    <button className={"add--spending__form-btn"} type="submit"
                        onClick={() => setIsActive(false)}>ДОБАВИТЬ</button>
                </div>
            </form>
        </div>
    );
};

export default AddSpendingForm;
