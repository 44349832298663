import React, {useEffect, useRef, useState} from 'react';
import './UploadSalesReportForm.css';
import DistributorSalesReportService from '../../../../services/DistributorSalesReportService';

interface UploadSalesReportFormProps {
  isActive: boolean;
  setActive: (isActive: boolean) => void;
}

const UploadSalesReportForm: React.FC<UploadSalesReportFormProps> = ({isActive, setActive}) => {
  const [file, setFile] = useState<File | null>(null);
  const [reportCount, setReportCount] = useState<number | null>(null);
  const [importMessagesCount, setImportMessagesCount] = useState<number | null>(null);
  const [numCpus, setNumCpus] = useState<number | null>(null);
  const [numThreads, setNumThreads] = useState<number | null>(null);
  const [logMtrrCount, setLogMtrrCount] = useState<number | null>(null);
  const [sourceReportRowsCount, setSourceReportRowsCount] = useState<number | null>(null);
  const [periods, setPeriods] = useState<string | null>(null);
  const [logMtrrCountAfterClean, setLogMtrrCountAfterClean] = useState<number | null>(null);
  const [srProfitChecksum, setSrProfitChecksum] = useState<string | null>(null);
  const [srStreamsChecksum, setSrStreamsChecksum] = useState<string | null>(null);
  const [dbStreamsChecksum, setDbStreamsChecksum] = useState<string | null>(null);
  const [dbProfitChecksum, setDbProfitChecksum] = useState<string | null>(null);
  const [mtrrCountAfterImport, setMtrrCountAfterImport] = useState<number | null>(null);
  const [mtrrImportedCount, setMtrrImportedCount] = useState<number | null>(null);
  const [mtrrAddedCount, setMtrrAddedCount] = useState<number | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const intervalRef = useRef<number>(10000);

  useEffect(() => {
    if (isActive) {
      const fetchSalesReportStats = async () => {
        try {
          const response = await DistributorSalesReportService.getDistributorSalesReportStats();
          setReportCount(response.musictrack_report_rows_count);
          setImportMessagesCount(response.import_messages_count);
          setNumCpus(response.num_cpus);
          setNumThreads(response.num_threads);
          setLogMtrrCount(response.log_mtrr_count);
          setSourceReportRowsCount(response.source_report_rows_count);
          setPeriods(response.periods);
          setLogMtrrCountAfterClean(response.log_mtrr_count_after_clean);
          setSrProfitChecksum(response.sr_profit_checksum);
          setSrStreamsChecksum(response.sr_streams_checksum);
          setDbStreamsChecksum(response.db_streams_checksum);
          setDbProfitChecksum(response.db_profit_checksum);
          setMtrrCountAfterImport(response.mtrr_count_after_import);
          setMtrrImportedCount(response.mtrr_imported_count);
          setMtrrAddedCount(response.mtrr_added_count);
        } catch (error) {
          console.error('Failed to fetch sales report stats:', error);
        }
      };
      clearInterval(intervalRef.current);
      // Set polling interval based on importMessagesCount
      const intervalTime = importMessagesCount !== null ? 2000 : 10000;
      // @ts-ignore
      intervalRef.current = setInterval(fetchSalesReportStats, intervalTime);

      // Fetch immediately on component mount
      fetchSalesReportStats();
    }
    // Cleanup function to clear the interval when component unmounts or isActive changes
    return () => clearInterval(intervalRef.current);
  }, [isActive]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (file) {
      setIsUploading(true);
      try {
        if (file.size > 5 * 1024 * 1024) {
          // Large file, use multipart upload
          const {uploadId, urls} = await DistributorSalesReportService.initiateMultipartUpload(file.name);
          const parts = await uploadParts(file, urls);
          await DistributorSalesReportService.completeMultipartUpload(uploadId, file.name, parts);
        } else {
          // Small file, use direct upload
          await DistributorSalesReportService.uploadSalesReport(file);
        }

        console.log('File uploaded successfully');
        resetForm();
      } catch (error) {
        console.error('Failed to upload file:', error);
      } finally {
        setIsUploading(false);
      }
    } else {
      alert('Please select a file to upload.');
    }
  };

  const resetForm = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setActive(false);
  };

  const uploadParts = async (file: File, urls: string | any[]) => {
    const chunkSize = 5 * 1024 * 1024; // 5MB per chunk
    const parts = [];

    for (let i = 0; i < urls.length; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const blob = file.slice(start, end);

      const eTag = await DistributorSalesReportService.uploadPart(urls[i], blob);
      parts.push({ETag: eTag, PartNumber: i + 1});

      // Early exit if last part
      if (end === file.size) break;
    }

    return parts;
  };

  return (
    <div className={`upload-sales-report-form ${isActive ? 'active' : ''}`}>
      <div className="form-container">
        <h2>Upload Distributor Sales Report</h2>
        <table className="upload-sales-report-stats-table">
          <tbody>
          {importMessagesCount != null && (
            <tr>
              <td>Import messages count in the queue:</td>
              <td>{importMessagesCount}</td>
            </tr>
          )}
          {reportCount != null && (
            <tr>
              <td>Current Sales Report DB Records Count:</td>
              <td>{reportCount}</td>
            </tr>
          )}
          {periods != null && (
            <tr>
              <td>Periods:</td>
              <td>{periods}</td>
            </tr>
          )}
          {logMtrrCount != null && (
            <tr>
              <td>Initial Sales Report DB Records Count:</td>
              <td>{logMtrrCount}</td>
            </tr>
          )}
          {logMtrrCountAfterClean != null && (
            <tr>
              <td>Sales Report DB Records Count After Clean:</td>
              <td>{logMtrrCountAfterClean}</td>
            </tr>
          )}
          {sourceReportRowsCount != null && (
            <tr>
              <td>Source Sales Report Rows Count:</td>
              <td>{sourceReportRowsCount}</td>
            </tr>
          )}
          {mtrrImportedCount != null && (
            <tr>
              <td>Sales Report DB Records Imported Count:</td>
              <td>{mtrrImportedCount}</td>
            </tr>
          )}
          {mtrrAddedCount != null && (
            <tr>
              <td>Sales Report DB Records Added Count:</td>
              <td>{mtrrAddedCount}</td>
            </tr>
          )}
          {mtrrCountAfterImport != null && (
            <tr>
              <td>Sales Report DB Records Count After Import:</td>
              <td>{mtrrCountAfterImport}</td>
            </tr>
          )}
          {srStreamsChecksum != null && (
            <tr>
              <td>Sales Report Streams Checksum:</td>
              <td>{srStreamsChecksum}</td>
            </tr>
          )}
          {dbStreamsChecksum != null && (
            <tr>
              <td>DB Streams Checksum:</td>
              <td>{dbStreamsChecksum}</td>
            </tr>
          )}
          {srProfitChecksum != null && (
            <tr>
              <td>Sales Report Profit Checksum:</td>
              <td>{srProfitChecksum}</td>
            </tr>
          )}
          {dbProfitChecksum != null && (
            <tr>
              <td>DB Profit Checksum:</td>
              <td>{dbProfitChecksum}</td>
            </tr>
          )}
          </tbody>
        </table>

        <hr/>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="fileInput">Select file:</label>
            <input
              type="file"
              id="fileInput"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept=".csv, .xlsx, .xls"
              disabled={isUploading}
            />
          </div>
          <div className="form-actions">
            <button type="submit" className="process-button" disabled={isUploading}>
              {isUploading ? 'Uploading...' : 'Process'}
            </button>
            <button type="button" className="close-button" onClick={resetForm} disabled={isUploading}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadSalesReportForm;
