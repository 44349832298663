import { authHeaders } from "../http/AuthHeaders";
const API_URL = process.env.REACT_APP_BACKEND_API_URL;
class ReleaseService {
    static async getReleases(pageNum: number, searchText: string) {
        try {
            const response = await fetch(`${API_URL}release?&page_num=${pageNum}&items_per_page=15&search_text=${searchText}`, {
                method: 'GET',
                headers: authHeaders()
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    }
}

export default ReleaseService