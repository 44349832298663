import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import IndividualReportsService from '../../../../../services/IndividualReportsService';

interface Values {
  payout_amount: string;
}

interface Props {
  defaultPayoutAmount: string;
  reportItemKey: string;
  handleExternalEvent: Function;
}

function cleanUpNumber(input: string): string {
  if (input.includes('-')) return '0';
  const result = input.replace(/[^0-9]/g, '');
  const numberResult = parseInt(result) / 100;
  return numberResult.toString();
}


function ReportPayoutAmountForm({ defaultPayoutAmount, reportItemKey, handleExternalEvent }: Props) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  const markPaidOut = (key: string, payout_amount: string) => {
    IndividualReportsService.markPaidOut(key, payout_amount).then(() => {
      console.log('report paid out');
      handleExternalEvent();
    })
  }

  useEffect(() => {
    function handleFocus(event: FocusEvent) {
      if (dialogRef.current && dialogRef.current.open && !dialogRef.current.contains(event.target as Node)) {
        event.stopPropagation();
        dialogRef.current.focus();
      }
    }

    document.addEventListener('focus', handleFocus, true);
    return () => {
      document.removeEventListener('focus', handleFocus);
    }
  }, []);

  const handleOpen = () => {
    if (dialogRef.current) dialogRef.current.showModal();
  };

  const handleClose = () => {
    if (dialogRef.current) dialogRef.current.close();
  };

  const handleValidate = (values: Values) => {
    const errors: Partial<Values> = {};
    if (!values.payout_amount) {
      errors.payout_amount = 'Required';
    } else if (isNaN(Number(values.payout_amount))) {
      errors.payout_amount = 'Must be a number';
    }
    return errors;
  };

  const handleSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    setTimeout(() => {
      console.log(`Submitted value for ${reportItemKey}: ` + JSON.stringify(values, null, 2));
      markPaidOut(reportItemKey, values.payout_amount);
      setSubmitting(false);
      handleClose();
    }, 400);
  };

  return (
    <div>
      <div className={"isPaid unpaid reports-item__flag"} onClick={handleOpen}>
        <span>Нет</span>
      </div>

      <dialog ref={dialogRef}>
        <Formik
          enableReinitialize={true}
          initialValues={{ payout_amount: cleanUpNumber(defaultPayoutAmount) }}
          validate={handleValidate}
          onSubmit={handleSubmit}
        >
          {formik => (
            <Form>
              <h1>Enter Payout Amount</h1>
              <p>
                <label htmlFor="payout_amount">Payout Amount</label>
                <Field type="number" step="0.01" name="payout_amount" />
                <ErrorMessage name="payout_amount" component="div" />
              </p>
              <menu>
                <button type="button" onClick={handleClose}>Cancel</button>
                <button type="submit" disabled={formik.isSubmitting}>Submit</button>
              </menu>
            </Form>
          )}
        </Formik>
      </dialog>
    </div>
  );
}

export default ReportPayoutAmountForm;
