import React, {useContext, useEffect, useState} from 'react';
import {useForm, Resolver, SubmitHandler} from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import '../Login.css'
import ServerErrors from "./ServerError/ServerErrors";
import {Context} from "../../../index";
import {observe} from "mobx";
import {AuthService} from '../../../services/AuthService'
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";

type LoginFormValues = {
    email: string;
    password: string;
}

const loginSchema = yup.object().shape({
    email: yup.string().email('Не валидная почта').required('Введите почту'),
    password: yup.string()
        .max(32, 'Пароль должен содержать не более 32 символов')
        .required('Введите пароль'),
});

const LoginForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<LoginFormValues>({resolver: yupResolver(loginSchema)});
    const [serverErrors, setServerErrors] = useState<String[]>([''])
    const [isFetching, setIsFetching] = useState<boolean>(false)
    const navigate = useNavigate()
    const {store} = useContext(Context)
    useEffect(() => {
        if(store.isAuth) {
            navigate('/cabinet/users')
        }
    }, [store.isAuth])
    const handlerSubmit: SubmitHandler<LoginFormValues> = async (data) => {
        try{
            setIsFetching(true)
            const res = await store.login(data.email, data.password)
            if(res?.error) setServerErrors([res.error])
            setIsFetching(false)
        }
        catch(e){
            console.log(e)
            setIsFetching(false)
        }
    };
    console.log(store.isFetching)
    return (
        <form className={"login__form"} onSubmit={handleSubmit(handlerSubmit)}>
            <div className={"login__form-field"}>
                <label>Email:</label>
                <input
                    className={
                        errors?.password ? "login__form-email login__form-input login-error"
                            : "login__form-email login__form-input"
                    }
                       {...register("email", {required: 'Введите электронную почту'})}
                />
                {errors?.email && <p className={"login__form-error"}>{errors.email.message}</p>}
            </div>
            <div className={"login__form-field last-child"}>
                <label>Пароль:</label>
                <input
                    type={"password"}
                    className={
                        errors?.password ? "login__form-password login__form-input login-error"
                            : "login__form-password login__form-input"
                    }
                       {...register("password", {required: 'Введите пароль'})}
                />
            </div>
            {/* <div className={"login__form-props"}>
                <div className={"forgot-password"}>
                    <a href={"#"}>Не помню</a>
                </div>
            </div> */}
            <ServerErrors errors={serverErrors} />
            <button className={isFetching ? "login__form-button button--loading" : "login__form-button"} type={"submit"}><span className={"button_text"}>Войти</span></button>
        </form>
    );
};

export default observer(LoginForm);