import React from 'react';
import TracksService from '../../../../../services/TracksService';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {toast} from 'react-toastify';


const SingleStepForm = (props: { setActive: (value: boolean) => void , handleExternalEvent: Function, musicTrack?: any }) => {
  const {setActive, handleExternalEvent, musicTrack} = props
  const transformLicensorsShares = (track
    ? : any
) =>
  {
    const initialValues: any = {
      distributor: '',
      label: '',
      artist: '',
      album: '',
      upc: '',
      article: '',
      release_date: '',
      license_end_date: '',
      title: '',
      isrc: '',
      remix: '',
      genre: '',
      length: '',
      broma16id: '',
      label_share: '',
      licensor1: '',
      share1: '',
      licensor2: '',
      share2: '',
      licensor3: '',
      share3: '',
      licensor4: '',
      share4: '',
      licensor5: '',
      share5: '',
      author1: '',
      author_share1: '',
      author2: '',
      author_share2: '',
      author3: '',
      author_share3: '',
      author4: '',
      author_share4: '',
      author5: '',
      author_share5: '',
      split_author_licensor_rights: ''
    };

    if (track) {
      //licensors and shares
      track.licensors.forEach((licensor: string, index: number) => {
        initialValues[`licensor${index + 1}`] = licensor;
      });

      track.shares.forEach((share: string, index: number) => {
        initialValues[`share${index + 1}`] = share.toString();
      });

      //authors and shares
      track.authors.forEach((author: string, index: number) => {
        initialValues[`author${index + 1}`] = author;
      });

      track.author_shares.forEach((author_share: string, index: number) => {
        initialValues[`author_share${index + 1}`] = author_share.toString();
      });

      // Copy other properties from the track
      Object.assign(initialValues, track, {
        isrc: track.isrc.join(', '), // Assuming ISRCs need to be a string; adjust if otherwise
      });
    }

    return initialValues;
  }
  ;

  return (
    <div className={"add-release__form-second-step__inner"}>
      <Formik
        enableReinitialize={true}
        initialValues={transformLicensorsShares(musicTrack)}
        validate={values => {
          const errors = {};
          // if (!values.email) {
          //     errors.email = 'Required';
          // } else if (
          //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          // ) {
          //     errors.email = 'Invalid email address';
          // }
          return errors;
        }}
        onSubmit={(values, {setSubmitting}) => {
          const action = musicTrack ? TracksService.editMusicTrack : TracksService.addMusicTrack;
          setTimeout(() => {
            console.log(JSON.stringify(values, null, 2));
            action(values).then((res) => {
              console.log(res);
              if (res instanceof Response) {
                if (res.status === 201) {
                  console.log('Track added');
                  toast('Music Track added');
                  handleExternalEvent();
                  setActive(false);
                } else if (res.status === 200) {
                  console.log('Track updated');
                  toast('Music Track updated');
                  handleExternalEvent();
                  setActive(false);
                } else if (res.status === 418) {
                  console.log('Track adding failed');
                  res.json().then((data) => {
                    toast(data.message);
                  });
                }
              }
            });
            setSubmitting(false);
          }, 400);
        }}
      >
        {({isSubmitting}) => (
          <Form>
            <div className={"add-release__form__wrapper"}>
              <div className={"add-release__txt-inputs"}>
                <div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'}
                           htmlFor="distributor">Дистрибьютор:</label>
                    <Field className={'add-release__txt-input'} type="string" name="distributor"/>
                    <ErrorMessage name="distributor" component="div"/>
                  </div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="label">Лейбл:</label>
                    <Field className={'add-release__txt-input'} type="string" name="label"/>
                    <ErrorMessage name="label" component="div"/>
                  </div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="artist">Артист:</label>
                    <Field className={'add-release__txt-input'} type="string" name="artist" required/>
                    <ErrorMessage name="artist" component="div"/>
                  </div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="album">Название релиза (сингла /
                      альбома):</label>
                    <Field className={'add-release__txt-input'} type="string" name="album" required/>
                    <ErrorMessage name="album" component="div"/>
                  </div>
                </div>
                <div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="upc">UPC:</label>
                    <Field className={'add-release__txt-input'} type="string" name="upc" required/>
                    <ErrorMessage name="upc" component="div"/>
                  </div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="article">Артикул:</label>
                    <Field className={'add-release__txt-input'} type="string" name="article"/>
                    <ErrorMessage name="article" component="div"/>
                  </div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="release_date">Дата
                      релиза:</label>
                    <Field className={'add-release__txt-input'} type="date" name="release_date"
                           required/>
                    <ErrorMessage name="release_date" component="div"/>
                  </div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="license_end_date">Дата окончания
                      лицензии:</label>
                    <Field className={'add-release__txt-input'} type="date" name="license_end_date"/>
                    <ErrorMessage name="license_end_date" component="div"/>
                  </div>
                </div>
              </div>
            </div>
            <div className={"add-release-track__info"}>
              <h3 className={"track-info__title release__form-info__title"}>Информация о треке:</h3>
              <div className={"add-release__txt-inputs"}>
                <div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="title">Название:</label>
                    <Field className={'add-release__txt-input'} type="string" name="title"/>
                    <ErrorMessage name="title" component="div"/>
                  </div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="isrc">ISRC:</label>
                    <Field className={'add-release__txt-input'} type="string" name="isrc" required/>
                    <ErrorMessage name="isrc" component="div"/>
                  </div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="remix">Версия:</label>
                    <Field className={'add-release__txt-input'} type="string" name="remix" required/>
                    <ErrorMessage name="remix" component="div"/>
                  </div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="genre">Жанр:</label>
                    <Field className={'add-release__txt-input'} type="string" name="genre" required/>
                    <ErrorMessage name="genre" component="div"/>
                  </div>
                </div>
                <div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="length">Длина:</label>
                    <Field className={'add-release__txt-input'} type="string" name="length"/>
                    <ErrorMessage name="length" component="div"/>
                  </div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="broma16id">BROMA16ID:</label>
                    <Field className={'add-release__txt-input'} type="string" name="broma16id"/>
                    <ErrorMessage name="broma16id" component="div"/>
                  </div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="label_share">Доля
                      PARADIGM:</label>
                    <Field className={'add-release__txt-input'} type="number" step="any"
                           name="label_share" required/>
                    <ErrorMessage name="label_share" component="div"/>
                  </div>

                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="label_share">Тип учёта
                      расходов:</label>
                    <Field className={'add-release__txt-input'} as="select"
                           name="expense_calculation_type" required>
                      <option value="common">С общих доходов</option>
                      <option value="individual">С доходов лицензиара</option>
                    </Field>
                    <ErrorMessage name="expense_calculation_type" component="div"/>
                  </div>

                </div>
              </div>
            </div>
            <div className={"add-release-licensors__info"}>
              <h3 className={"release__form-info__title"}>Считать с разделением на авторские и смежные права:</h3>
              <div className={"split_author_licensor_rights"}>
                <label className={'add-release__label'}>Считать с разделением на авторские и
                смежные права:</label>
                <div className="custom-checkbox">
                <Field type="checkbox" id="split_author_licensor_rights" name="split_author_licensor_rights"/>
                <label htmlFor="split_author_licensor_rights"></label>
                </div>
                <ErrorMessage name="split_author_licensor_rights" component="div"/>
              </div>

            </div>
            <div className={"add-release-licensors__info"}>
              <h3 className={"licenses-info__title release__form-info__title"}>Информация о
                Лицензиарах:</h3>
              <div className={"add-release__txt-inputs"}>
                <div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="licensor1">Лицензиар 1:</label>
                    <Field className={'add-release__txt-input'} type="string" name="licensor1"
                           required/>
                    <ErrorMessage name="licensor1" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="licensor2">Лицензиар 2:</label>
                    <Field className={'add-release__txt-input'} type="string" name="licensor2"/>
                    <ErrorMessage name="licensor2" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="licensor3">Лицензиар 3:</label>
                    <Field className={'add-release__txt-input'} type="string" name="licensor3"/>
                    <ErrorMessage name="licensor3" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="licensor4">Лицензиар 4:</label>
                    <Field className={'add-release__txt-input'} type="string" name="licensor4"/>
                    <ErrorMessage name="licensor4" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="licensor5">Лицензиар 5:</label>
                    <Field className={'add-release__txt-input'} type="string" name="licensor5"/>
                    <ErrorMessage name="licensor5" component="div"/>
                  </div>
                </div>
                <div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="share1">Доля лицензиара
                      1:</label>
                    <Field className={'add-release__txt-input'} type="number" step="any" max="100"
                           name="share1" required/>
                    <ErrorMessage name="share1" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="share2">Доля лицензиара
                      2:</label>
                    <Field className={'add-release__txt-input'} type="number" step="any" max="100"
                           name="share2"/>
                    <ErrorMessage name="share2" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="share3">Доля лицензиара
                      3:</label>
                    <Field className={'add-release__txt-input'} type="number" step="any" max="100"
                           name="share3"/>
                    <ErrorMessage name="share3" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="share4">Доля лицензиара
                      4:</label>
                    <Field className={'add-release__txt-input'} type="number" step="any" max="100"
                           name="share4"/>
                    <ErrorMessage name="share4" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="share5">Доля лицензиара
                      5:</label>
                    <Field className={'add-release__txt-input'} type="number" step="any" max="100"
                           name="share5"/>
                    <ErrorMessage name="share5" component="div"/>
                  </div>
                </div>
              </div>
            </div>
            <div className={"add-release-licensors__info"}>
              <h3 className={"licenses-info__title release__form-info__title"}>Информация об
                Авторах:</h3>
              <div className={"add-release__txt-inputs"}>
                <div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="author1">Автор 1:</label>
                    <Field className={'add-release__txt-input'} type="string" name="author1"/>
                    <ErrorMessage name="author1" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="author2">Автор 2:</label>
                    <Field className={'add-release__txt-input'} type="string" name="author2"/>
                    <ErrorMessage name="author2" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="author3">Автор 3:</label>
                    <Field className={'add-release__txt-input'} type="string" name="author3"/>
                    <ErrorMessage name="author3" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="author4">Автор 4:</label>
                    <Field className={'add-release__txt-input'} type="string" name="author4"/>
                    <ErrorMessage name="author4" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="author5">Автор 5:</label>
                    <Field className={'add-release__txt-input'} type="string" name="author5"/>
                    <ErrorMessage name="author5" component="div"/>
                  </div>
                </div>
                <div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="author_share1">Доля автора
                      1:</label>
                    <Field className={'add-release__txt-input'} type="number" step="any" max="100"
                           name="author_share1"/>
                    <ErrorMessage name="author_share1" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="author_share2">Доля автора
                      2:</label>
                    <Field className={'add-release__txt-input'} type="number" step="any" max="100"
                           name="author_share2"/>
                    <ErrorMessage name="author_share2" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="author_share3">Доля автора
                      3:</label>
                    <Field className={'add-release__txt-input'} type="number" step="any" max="100"
                           name="author_share3"/>
                    <ErrorMessage name="author_share3" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="author_share4">Доля автора
                      4:</label>
                    <Field className={'add-release__txt-input'} type="number" step="any" max="100"
                           name="author_share4"/>
                    <ErrorMessage name="author_share4" component="div"/>
                  </div>
                  <div className={"add-release__txt-inputs__item"}>
                    <label className={'add-release__label'} htmlFor="author_share5">Доля автора
                      5:</label>
                    <Field className={'add-release__txt-input'} type="number" step="any" max="100"
                           name="author_share5"/>
                    <ErrorMessage name="author_share5" component="div"/>
                  </div>
                </div>
              </div>
            </div>
            <div className={"add-release__form__btns"}>
              <button type="submit" disabled={isSubmitting}
                      className={"add-release__form-submit-btn add-release__form-btn"}>
                Сохранить
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SingleStepForm;
