import {IUser} from "../models/IUser";
import {makeAutoObservable} from "mobx";
import {AuthService} from "../services/AuthService";
export default class Store {
    user = {} as IUser;
    isAuth = false;
    isFetching = false;
    // reports = [] as REPORTS NOT GLOBAL
    constructor() {
        makeAutoObservable(this)
    }

    setAuth(value: boolean) {
        this.isAuth = value
    }
    setIsFetching(value: boolean) {
        this.isFetching = value
    }
    setUser(user: IUser) {
        this.user = user
    }

    async checkAuth() {
        try {
            this.setIsFetching(true)
                const fetchIsAuth = await AuthService.checkAuth()
                if (fetchIsAuth === 1) {
                    this.setUser(JSON.parse(localStorage.getItem('user') || '{}'))
                    this.setAuth(true)
                    this.setIsFetching(false)
                } else {
                    this.setUser({} as IUser)
                    this.setAuth(false)
                    this.setIsFetching(false)
                }
                return this.isAuth
            }
        catch (e) {
            console.log(e)
        }
    }

    async login(email: string, password: string){
        try {
            const response = await AuthService.login(email, password)
            if(response.Code !== 'UnauthorizedError'){
                localStorage.setItem('user', JSON.stringify(response))
                this.setAuth(true)
                this.setUser(response)
            }
            else {
                this.isFetching = false
                return {error: "Неверная почта или пароль"}
            }
        }
        catch (e){
            console.log(e)
        }
    }
    logout () {
        AuthService.logout()
        this.setAuth(false)
        this.setUser({} as IUser)
    }

    async telegramLogin(user: any){
        try {
            AuthService.telegramLogin(user)
            .then((response) => {
                if(response.Code !== 'UnauthorizedError'){
                    localStorage.setItem('user', JSON.stringify(response))
                    this.setAuth(true)
                    this.setUser(response)
                }
                else {
                    this.isFetching = false
                    return {error: "Что-то пошло не так"}
                }
            })
        }
        catch (e){
            console.log(e)
        }
    }
}