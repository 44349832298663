import React, {Dispatch, SetStateAction} from 'react';
import docImg from "../../../../assets/img/doc.png";
import userIcon from '../../../../assets/img/userIcon.png'
import editIcon from '../../../../assets/img/edit icon white 1.svg'
import '../Users.css'
import {ISummary} from "../Users";
const UsersItem = (props: {item: ISummary, key: number, setIsEditUserPopupOpen: Dispatch<SetStateAction<boolean>>, setCurrentUser: Dispatch<SetStateAction<ISummary | null>>}) => {
    const {item, setIsEditUserPopupOpen, setCurrentUser} = props
    const handleDownload = (name: string) => {
        window.alert('Данная функция в разработке')
    }
    return (
        <div className={"dashboard__list-item"}>
            <div className={"list__item-doc__wrapper"}>
                <span onClick={()=>handleDownload(item.key)} className={"list__item-doc"}>
                    <img src={userIcon} alt=""/>
                </span>
            </div>
            <p className={"list__item-quarter"}>{item.full_name}</p>
            {/*<p className={"list__item-auditions"}>{item?.artists.length ? item.artists.map(artist => {*/}
            {/*    const artistsArray = item.artists*/}
            {/*        if(item.artists?.length !== 1 && artist !== item.artists[artistsArray.length - 1]) return artist + ", "*/}
            {/*        else return artist*/}
            {/*}) : "без имени"}</p>*/}
            <p className={"list__item-auditions"}>{item?.nickname ? item.nickname : "без имени"}</p>
            <p className={"list__item-total"}>{item.email}</p>
            <div className={"list__item-edit"}>
                <img onClick={() => {
                    setIsEditUserPopupOpen(true)
                    setCurrentUser(item)
                }} src={editIcon} alt=""/>
            </div>
            <div className={"list__item-download-btn__wrapper"}>
                <button className={"list__item-download-btn"}
                        onClick={()=>handleDownload(item.key)}
                >Подробнее</button>
            </div>
        </div>
    );
};

export default UsersItem;
