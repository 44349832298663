import { authHeaders } from "../http/AuthHeaders";
const API_URL = process.env.REACT_APP_BACKEND_API_URL;

class IndividualReportsService {

    static async getReports(searchText: string, requestId: number) {
        return fetch(`${API_URL}individual-report?search_text=${searchText}`, {
            method: 'GET',
            headers: authHeaders()
        })
        .then(res => res.json())
        .then(data => ({ data, requestId }))
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    static async download(key: string, period: string, individual: string) {
        return fetch(`${API_URL}download-individual-report`, {
            method: 'POST',
            headers:
                Object.assign({}, authHeaders(), { 'Accept': 'application/octet-stream, binary/octet-stream' }),
            body: JSON.stringify({ key })
        })
            .then(res => res.blob())
            .then(blob => {
                var fileUrl = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = fileUrl;
                a.download = period + '_' + individual + '.xlsx';
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    static async markSentOut(key: string) {
        return fetch(`${API_URL}individual-report/mark-as-sent-out`, {
            method: 'PUT',
            headers:
                Object.assign({}, authHeaders(), { 'Accept': 'application/json' }),
            body: JSON.stringify({ key })
        })
            .catch((error) => {
                console.error('Error:', error);
            })

    }

    static async markPaidOut(key: string, payout_amount: string) {
        return fetch(`${API_URL}individual-report/mark-as-paid-out`, {
            method: 'PUT',
            headers:
                Object.assign({}, authHeaders(), { 'Accept': 'application/json' }),
            body: JSON.stringify({ key, payout_amount })
        })
            .catch((error) => {
                console.error('Error:', error);
            })

    }

    static async addPayout(individual: string, payout_amount: string) {
        return fetch(`${API_URL}moneyflow/add-payout`, {
            method: 'POST',
            headers:
                Object.assign({}, authHeaders(), { 'Accept': 'application/json' }),
            body: JSON.stringify({ individual, payout_amount })
        })
            .catch((error) => {
                console.error('Error:', error);
            })

    }


}

export default IndividualReportsService