import React, {useState} from 'react';
import './Search.css'
const Search = (props: { searchTextSetter: Function }) => {
    const { searchTextSetter } = props
    const [searchText, setSearchText] = useState<string>("")
    return (
        <div className={"search"}>
            <p className={"search__title"}>Поиск по расходам (Артист, Название релиза):</p>
            <input onChange={(e) => {
                // searchTextSetter(e.target.value); // Пока не ищем, игнорируем ввод. Потому что на бэке поиск только по ФИО физика, а для расходов ФИО не указано.
                setSearchText(e.target.value)
            }} value={searchText} className={"search__input"} type="text"/>
        </div>
    );
};

export default Search;