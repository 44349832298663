import React, { useState } from 'react';
import './Pagination.css'

const LEFT_PAGE = 'LEFT_PAGE'
const RIGHT_PAGE = 'RIGHT_PAGE'

const range = (from: number, to: number, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
}

const Pagination = (props: { currentPage: number, paginate: any, itemsPerPage: number, totalItems: number }) => {
    const pageNumbers: Array<number> = []
    const [currentNumbersPage, setCurrentNumbersPage] = useState<number>(0)
    const [pagesArrState, setPagesArrState] = useState<number[]>([])
    const { itemsPerPage, totalItems, paginate, currentPage } = props
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i)
    }

    const fetchPages = () => {
        const totalPages = pageNumbers.length
        const pageNeighbours = 1
        const totalNumbers = pageNeighbours * 2 + 3
        const totalBlocks = totalNumbers + 2

        if (totalPages > totalBlocks) {
            let pages = []

            const leftSide = currentPage - pageNeighbours
            const rightSide = currentPage - pageNeighbours
            const beforeLastPage = totalPages - 1
            const startPage = leftSide > 2 ? leftSide : 2;
            const endPage = rightSide < beforeLastPage ? rightSide : beforeLastPage;

            pages = range(startPage, endPage);

            const pagesCount = pages.length;
            const singleSpillOffset = totalNumbers - pagesCount - 1;

            const leftSpill = startPage > 3;
            const rightSpill = endPage < beforeLastPage;

            const leftSpillPage = LEFT_PAGE;
            const rightSpillPage = RIGHT_PAGE;

            if (leftSpill && !rightSpill) {
                const extraPages = range(startPage - singleSpillOffset, startPage - 1);
                pages = [leftSpillPage, ...extraPages, ...pages];
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(endPage + 1, endPage + singleSpillOffset);
                pages = [...pages, ...extraPages, rightSpillPage];
            } else if (leftSpill && rightSpill) {
                pages = [leftSpillPage, ...pages, rightSpillPage];
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);


    }
    const numbersComponent = (isMobile: boolean) => {
        console.log(pageNumbers.length)
        const numberPagesCount = pageNumbers.length / 4
        const arrays: Array<Array<number>> = []
        let size = 4
        if (isMobile) size = 3
        for (let i = 0; i < Math.ceil(pageNumbers.length / size); i++) {
            arrays[i] = pageNumbers.slice((i * size), (i * size) + size)
        }
        let pagesArr: Array<number> = []
        arrays.forEach((el) => {
            if (el.includes(currentPage)) {
                pagesArr = el
            }
        })
        return pagesArr.map(page => <div onClick={() => paginate(page)} className={page === currentPage ? "pagination__number active" : "pagination__number"} key={page}><span>{page}</span></div>
        )
    }
    return (
        <nav className={"pagination_nav"}>
            <ul className={"pagination"}>
                <button disabled={currentPage <= 1} onClick={() => paginate(currentPage - 1)} className={"arrow-left"}>{"<"}</button>
                <div className={"desktop-btns"}>
                    {numbersComponent(false)}
                    {/*{pageNumbers.slice(0, 4).map((num, key) => <div key={key} onClick={() => paginate(num)} className={num === currentPage ? "pagination__number active" : "pagination__number"}><span>{num}</span></div>)}*/}
                </div>
                <div className={"mobile-btns"}>
                    {numbersComponent(true)}
                    {/*{pageNumbers.slice(0, 3).map((num, key) => <div key={key} onClick={() => paginate(num)} className={num === currentPage ? "pagination__number active" : "pagination__number"}><span>{num}</span></div>)}*/}
                </div>
                ...
                <div onClick={() => paginate(pageNumbers[pageNumbers.length - 1])} className={pageNumbers[pageNumbers.length - 1] === currentPage ? "pagination__number active last" : "pagination__number last"}><span>{pageNumbers[pageNumbers.length - 1]}</span></div>
                <button disabled={currentPage >= pageNumbers[pageNumbers.length - 1]} onClick={() => paginate(currentPage + 1)} className={"arrow-right"}>{">"}</button>
            </ul>
        </nav>
    );
};

export default Pagination;