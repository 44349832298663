import React from 'react';
import './Login.css'
import logo from '../../assets/img/PARADIGM Logo 2022 (WHITE) 1.png'
import LoginForm from "./LoginForm/LoginForm";
import {observer} from "mobx-react-lite";
import TelegramLogin from "./TelegramLogin";

const Login = () => {
    return (
        <div className={"login__container"}>
            <div className={"login__inner"}>
                <img src={logo} className={"login__logo"} />
                <img src={logo} className={"login__logo mobile"} />
                <div className="login">
                    <h1 className={"login__form-title"}>Вход в бэк офис</h1>
                    <LoginForm/>
                    <br/>
                    <TelegramLogin/>
                    <div className={"bg-circle"}/>
                </div>
            </div>
        </div>
    );
};

export default observer(Login);