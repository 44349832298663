import React from 'react';
import './Cabinet.css'
import {NavLink, Route, Routes} from "react-router-dom";
import logo from '../../assets/img/PARADIGM Logo 2022 (WHITE) 1.png'
import NavBar from "../NavBar/NavBar";
import Users from "./Users/Users";
import Catalog from "./Catalog/Catalog";
import Reports from "./Reports/Reports";
import Expenses from "./Expenses/Expenses";
import Balances from './Balances/Balances';
const Cabinet = () => {
    return (
        <div className={"cabinet__container"}>
            <div className={"cabinet__top"}>
                <div className={"cabinet__top-info"}>
                    <div className={"logo__wrapper"}>
                        <img className={"logo"} src={logo} alt=""/>
                    </div>
                </div>
                <NavBar />
            </div>
            <div className={"cabinet__bottom"}>
                <Routes>
                    <Route path={"users"} element={<Users />} />
                    <Route path={"catalog"} element={<Catalog />} />
                    <Route path={"reports"} element={<Reports />} />
                    <Route path={"expenses"} element={<Expenses />} />
                    <Route path={"balances"} element={<Balances />} />
                </Routes>
            </div>
        </div>
    );
};

export default Cabinet;