import React, { useState } from 'react';
import './Release.css'
import { IRelease } from "../Catalog";
import trackIcon from '../../../../assets/img/trackIcon.png'
import infoIcon from '../../../../assets/img/info icon 1.svg'
import deleteIcon from '../../../../assets/img/delete icon 1.svg'
import editIcon from '../../../../assets/img/edit (icon) 1.svg'
import dollarIcon from '../../../../assets/img/cost icon.svg'
import TracksService from "../../../../services/TracksService";
import { toast } from 'react-toastify';
const Track = (props: { release: IRelease, setIsAddInvestOpen: any, handleExternalEvent: Function, openFormForEdit: Function }) => {
    const { release, setIsAddInvestOpen, handleExternalEvent, openFormForEdit } = props
    const [isTrackListOpen, setIsTrackListOpen] = useState<boolean>(false)
    let releaseName = release.musictracks[0]?.album
    let artist = release.musictracks[0]?.artist
    let releaseDate = release.musictracks[0]?.release_date
    let releaseLabel = release.musictracks[0]?.label
    let releaseExpenseCalculationType: string = release.musictracks[0]?.expense_calculation_type

    const handleDeleteTrack = (upc: string) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            TracksService.deleteTracks(upc).then(res => {
                if (res instanceof Response) {
                    if (res.status === 200) {
                        res.json().then(data => {
                            toast(data.message);
                        })
                    }
                    else if (res.status === 418) {
                        res.json().then(data => {
                            toast.error(data.message);
                        });
                    }
                }
                handleExternalEvent();
            })
        }
    };

    const handleEditTrack = (track: any) => {
        openFormForEdit(track);
    };


    const ExpenseCalculationType: Record<string, string> = {
        'common': "С общих доходов",
        'individual': "С доходов лицензиара",
    }

    function toReadableString(key: string): string {
        return ExpenseCalculationType[key] || key;
    }


    return (
        <div className={"track__list-item"}>
            <div className={"release__info-container"}>
                <div className={"track__top"}>
                    <div className={"track__info"}>
                        <div className={"track-image__container"}>
                            <img src={trackIcon} alt="" width="100" height="100"/>
                        </div>
                        <div className={"track__info-meta"}>
                            <h4 className={"track__title"}>
                                {releaseName}
                            </h4>
                            <p className={"track__artist"}>{artist}</p>
                            <p className={"track__upc"}>UPC: <span>{release.upc}</span></p>
                            <p className={"track__label"}>Лейбл: <span>{releaseLabel}</span></p>
                            <p className={"track__label"}>Расход: <span>{toReadableString(releaseExpenseCalculationType)}</span></p>

                        </div>
                    </div>
                    <div className={"track-tools"}>
                        {/* <img src={infoIcon} alt="" /> */}
                         {/*<img src={editIcon} alt="" />*/}
                        <img className={"delete_track_icon"} src={deleteIcon} alt="" onClick={() => handleDeleteTrack(release.upc)} />
                    </div>
                </div>
                <div className={"track__bottom"}>
                    <div className={"left-side"}>
                        <div className={"release-date"}>Дата релиза: <span>{releaseDate}</span></div>
                        <a className={`track-list__btn ${isTrackListOpen ? 'open' : ''}`} onClick={() => setIsTrackListOpen(!isTrackListOpen)}>Трек лист {!isTrackListOpen ?
                            <span className={"track-list__arrow-open"}>{">"}</span> : <span className={"track-list__arrow-close"}>{">"}</span>}
                        </a>
                    </div>
                    {/* <div className={"add-invest__btn__container"}>
                        <button className={"add-invest__btn"} onClick={() => setIsAddInvestOpen('release', releaseName, release.upc)}>ДОБАВИТЬ РАСХОД ПО РЕЛИЗУ</button>
                    </div> */}
                </div>
            </div>
            {
                isTrackListOpen && <div className={"track-list__wrapper"}>
                    <div className={"track-list__names"}>
                        <div className={"track-list__names__inner"}>
                            <div className={"track-list__names__inner__left"}>
                            <div className={"track-list__names-number"}></div>
                            <div className={"track-list__names-name"}>Название</div>
                            <div className={"track-list__names-name"}>Версия</div>
                            <div className={"track-list__names-artist"}>Артист</div>
                            <div className={"track-list__names-length"}>Длительность</div>
                            <div className={"track-list__names-length"}>ISRC</div>
                            <div className={"track-list__names-rights-percentage"}>Доля прав</div>
                            </div>
                            <div className={"track-list__names-empty"}></div>
                        </div>
                    </div>
                    {
                        release.musictracks.map((track, key) => {
                            return <div key={key} className={"track-list__values"}> <div className={"track-list__values__inner"}>
                                <div className={"track-list__values__inner__left"}>
                                    <div className={"track-list__values-number track-list__values__item"}>{key + 1}</div>
                                <div className={"track-list__values-name track-list__values__item"}>{track.title}</div>
                                <div className={"track-list__values-name track-list__values__item"}>{track.remix}</div>
                                <div className={"track-list__values-artist track-list__values__item"}>{track.artist}</div>
                                <div className={"track-list__values-length track-list__values__item"}>{track.length}</div>
                                <div className={"track-list__values-length track-list__values__item"}>
                                    {track.isrc.map((isrc) => <div>
                                        <span>{isrc}</span></div>
                                        )}</div>
                                <div className={"track-list__values-rights-percentage track-list__values__item"}>
                                    {track.licensors.map((licensor, key) => <div>
                                        <span>{licensor} - </span>
                                        <span>{Math.round(track.shares[key])}%</span>
                                    </div>)}
                                </div>
                                </div>
                                <div className={"track-tools"}>
                                    <img src={editIcon} alt="Edit"
                                      onClick={() => handleEditTrack(track)}
                                    />
                                    {/* <img onClick={() => setIsAddInvestOpen('track', track.title, track.upc)} src={dollarIcon} alt="" /> */}
                                </div>
                            </div>
                            </div>
                        })
                    }
                </div>
            }
        </div>)
};

export default Track;
