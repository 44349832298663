import { authHeaders } from "../http/AuthHeaders";
const API_URL = process.env.REACT_APP_BACKEND_API_URL;
class MusicService {
    static async getTracks(upc: string) {
        try {
            const response = await fetch(`${API_URL}musictrack?upc=${upc}`, {
                method: 'GET',
                headers: authHeaders()
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    static async addMusicTrack(inputData: {
        distributor: string, label: string, artist: string, album: string, upc: string,
        release_date: string, license_end_date: string, title: string, isrc: string, remix: string, genre: string, length: string,
        broma16id: string, label_share: string,
        licensor1: string, share1: string, licensor2: string, share2: string, licensor3: string, share3: string,
        licensor4: string, share4: string, licensor5: string, share5: string,
        author1: string, author_share1: string, author2: string, author_share2: string, author3: string,
        author_share3: string, author4: string, author_share4: string, author5: string, author_share5: string
    }) {
        return fetch(`${API_URL}musictrack`, {
            method: 'POST',
            headers:
                Object.assign({}, authHeaders(), { 'Accept': 'application/json' }),
            body: JSON.stringify({
                inputData
            })
        }).catch((error) => {
            console.error('Error:', error);
        })

    }

    static async editMusicTrack(inputData: {
        distributor: string, label: string, artist: string, album: string, upc: string,
        release_date: string, license_end_date: string, title: string, isrc: string, remix: string, genre: string, length: string,
        broma16id: string, label_share: string,
        licensor1: string, share1: string, licensor2: string, share2: string, licensor3: string, share3: string,
        licensor4: string, share4: string, licensor5: string, share5: string,
        author1: string, author_share1: string, author2: string, author_share2: string, author3: string,
        author_share3: string, author4: string, author_share4: string, author5: string, author_share5: string
    }) {
        return fetch(`${API_URL}musictrack`, {
            method: 'PUT',
            headers: Object.assign({}, authHeaders(), { 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                inputData
            })
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    static async deleteTracks(upc: string) {
        return fetch(`${API_URL}musictrack?upc=${upc}`, {
            method: 'DELETE',
            headers: authHeaders()
        }).catch((error) => {
            console.error('Error:', error);
        })
    }
}

export default MusicService