import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {useForm, SubmitHandler} from "react-hook-form";
import './AddUserForm.css'
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

type AddUserFormValues = {
    full_name: string;
    nickname: string;
    email: string;
    tg_nick: string;
}

const addUserSchema = yup.object().shape({
    full_name: yup.string().required('Введите ФИО артиста'),
    nickname: yup.string().required('Введите никнейм артиста'),
    tg_nick: yup.string(), //.required('Введите свой никнейм в telegram'),
    email: yup.string().email('Не валидная почта').required('Введите почту артиста')
});

const AddUserForm = (props: {setActive: (value: boolean) => void, addUserHandler: Dispatch<SetStateAction<any>>, setCurrentUser: Dispatch<SetStateAction<any>>, isActive: boolean}) => {
    const { reset, register, handleSubmit, formState: { errors } } = useForm<AddUserFormValues>({resolver: yupResolver(addUserSchema)});
    const {setActive,isActive, addUserHandler, setCurrentUser} = props
    const onSubmit: SubmitHandler<AddUserFormValues> = data => {
        addUserHandler(data)
        reset();
    }
    useEffect(() => {
        if(!isActive) reset()
    }, [isActive])
    return (
        <div className={"add-user__form__container"}>
            <h3 className={"add-user__form-title"}>⚡️ Добавить Пользователя</h3>
            <form className={"add-user__form"} onSubmit={handleSubmit(onSubmit)}>
                <label className={"add-user__form-label"}>Фамилия Имя Отчество</label>
                <input className={"add-user__form-input"} {...register("full_name")} type="text"/>
                <label className={"add-user__form-label"}>Никнейм Артиста</label>
                <input className={"add-user__form-input"} {...register("nickname")} type="text"/>
                <label className={"add-user__form-label"}>Email</label>
                <input className={"add-user__form-input"} {...register("email")} type="text"/>
                <label className={"add-user__form-label"}>Telegram ник <span>(для отправки оповещений)</span></label>
                <input className={"add-user__form-input"} {...register("tg_nick")} type="text"/>
                <div className={"add-user__form-buttons"}>
                    <button onClick={() => {
                        setActive(false)
                        reset()
                    }} className={"add-user__form-cancel__btn"}>ОТМЕНА</button>
                    <button type={"submit"} className={"add-user__form-cancel__btn"}>ДОБАВИТЬ</button>
                </div>
            </form>
        </div>
    );
};

export default AddUserForm;
