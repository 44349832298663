import { authHeaders } from "../http/AuthHeaders";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

class DistributorSalesReportService {
  static async uploadSalesReport(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    const headers = authHeaders();

    // Remove 'Content-Type' from headers if it exists, as it will be set automatically by the browser
    if (headers && 'Content-Type' in headers) {
      delete headers['Content-Type'];
    }

    try {
      const response = await fetch(`${API_URL}distributor-sales-report`, {
        method: 'POST',
        headers: {
          ...headers,
        },
        body: formData,
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw new Error('Failed to upload sales report');
    }
  }

  static async initiateMultipartUpload(fileName: any) {
    try {
      const response = await fetch(`${API_URL}initiate-multipart-upload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeaders(),
        },
        body: JSON.stringify({ fileName }),
      });
      if (!response.ok) {
        throw new Error('Failed to initiate multipart upload');
      }
      return await response.json(); // { uploadId, urls: [url1, url2, ...] }
    } catch (error) {
      console.error('Error:', error);
      throw new Error('Failed to initiate multipart upload');
    }
  }

  static async uploadPart(url: string | URL | Request, blob: any) {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: blob,
      });
      if (!response.ok) {
        throw new Error('Failed to upload part');
      }
      const eTag = response.headers.get('ETag');
      return eTag;
    } catch (error) {
      console.error('Error:', error);
      throw new Error('Failed to upload part');
    }
  }

  static async completeMultipartUpload(uploadId: any, fileName: any, parts: any) {
    try {
      const response = await fetch(`${API_URL}complete-multipart-upload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeaders(),
        },
        body: JSON.stringify({ uploadId, fileName, parts }),
      });
      if (!response.ok) {
        throw new Error('Failed to complete multipart upload');
      }
      return await response.json();
    } catch (error) {
      console.error('Error:', error);
      throw new Error('Failed to complete multipart upload');
    }
  }

  static async getDistributorSalesReportStats() {
    try {
      const response = await fetch(`${API_URL}distributor-sales-report-stats`, {
        method: 'GET',
        headers: authHeaders(),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch sales report count');
      }
      return await response.json();
    } catch (error) {
      console.error('Error:', error);
      throw new Error('Failed to fetch sales report count');
    }
  }
}

export default DistributorSalesReportService;
