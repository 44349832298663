import React, {useEffect, useRef, useState} from 'react';
import './Reports.css'
import {periodDecrypt} from "../../../helpers/periodDecrypt";
import Search from "./Search/Search";
import IndividualReportsService from "../../../services/IndividualReportsService";
import ReportItem from './ReportItem/ReportItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import UploadSalesReportForm from "./UploadSalesReportForm/UploadSalesReportForm";
import Popup from "../../Popup/Popup";

export interface ISummary {
    email: string;
    nickname: string;
    file_name: string;
    full_name: string;
    key: string;
    period: string;
    number_of_plays: string;
    profit: string;
    sent_out: boolean;
    approved_by_individual: boolean;
    payout_requested: boolean;
    paid_out: boolean;
    period_profit: string;
}


const Reports = () => {
    const [reports, setReports] = useState<ISummary[]>([])
    const [items, setItems] = useState<ISummary[]>([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [triggerCounter, setTriggerCounter] = useState(0);
    const [searchText, setSearchText] = useState<string>("")
    const latestRequestIdRef = useRef(0);
    const [isUploadDistributorsSalesReportPopupOpen, setIsUploadDistributorsSalesReportPopupOpen] = useState<boolean>(false)

    const handleExternalEvent = () => {
        // increment counterRef to trigger the effect to run again
        setTriggerCounter(triggerCounter + 1);
    }

    const fetchData = () => {
        setPage(page + 1);
        var report_items = reports.slice(0, page * 20);
        setItems(report_items);
        setHasMore(report_items.length < reports.length)
    }

    useEffect(() => {
        if (triggerCounter === 0) {
            return;
        }
        const requestId = Date.now();
        latestRequestIdRef.current = requestId;

        IndividualReportsService.getReports(searchText, requestId).then(response => {
            if (response && response.requestId === latestRequestIdRef.current) {
                setReports(response.data);
            }
        });
    }, [triggerCounter]);

    useEffect(() => {
        const requestId = Date.now();
        latestRequestIdRef.current = requestId;

        const timer = setTimeout(() => {
            IndividualReportsService.getReports(searchText, requestId).then(response => {
                if (response && response.requestId === latestRequestIdRef.current) {
                    setReports(response.data);
                }
            });
        }, 400);

        return () => clearTimeout(timer);
    }, [searchText]);

    useEffect(() => {
        setPage(0);
        fetchData();
    }, [reports])

    return (
      <div>
          <Search searchTextSetter={setSearchText}/>
          <div className={"add-report__btn__inner"}>
              <button onClick={() => setIsUploadDistributorsSalesReportPopupOpen(true)} className={"add-report__btn"}><p>Загрузить отчёт дистрибьютора</p></button>
          </div>
          <InfiniteScroll
            dataLength={items.length} //This is important field to render the next data
            next={fetchData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={
                <p style={{textAlign: 'center'}}>
                    <b>Все загруженные элементы отображены.</b>
                </p>
            }
          >
              <div className={"reports__dashboard"}>
                  <table className={"reports__dashboard-table"}>
                      <thead className={"reports-table__head"}>
                      <tr>
                          <th className={"profile__icon-th"}></th>
                          <th className={"fio"}>ФИО</th>
                          <th>Отчётный период</th>
                          <th>Сумма по отчету</th>
                          <th>Сумма к выплате</th>
                          <th>Выслано</th>
                          <th>Согласовано</th>
                          <th>Запрошено</th>
                          <th>Выплачено</th>
                          <th className={"send-in-tg-th"}>Отправить в Telegram</th>
                      </tr>
                      </thead>
                      <tbody className={"reports-table__body"}>
                      {
                          items?.map((item: ISummary, id) => {
                              // total = total + parseFloat(item.profit)
                              const newPeriod: string = periodDecrypt(item.period)
                              // item.period = newPeriod
                              const newProfit = item.profit.toString().split('.')[0]
                                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                              const nProfit = Intl.NumberFormat(undefined, {
                                  style: 'currency',
                                  currency: 'RUB'
                              }).format(parseFloat(item.profit));
                              const newNumberOfPlays = item.number_of_plays = item.number_of_plays.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                              const newItem: ISummary = {
                                  ...item,
                                  period: newPeriod,
                                  profit: nProfit,
                                  number_of_plays: newNumberOfPlays
                              }
                              return <ReportItem handleExternalEvent={handleExternalEvent} key={id} item={newItem}/>
                          })
                      }
                      </tbody>
                  </table>
              </div>
          </InfiniteScroll>
          <Popup isActive={isUploadDistributorsSalesReportPopupOpen} setActive={setIsUploadDistributorsSalesReportPopupOpen}>
              <UploadSalesReportForm isActive={isUploadDistributorsSalesReportPopupOpen} setActive={setIsUploadDistributorsSalesReportPopupOpen}/>
          </Popup>
      </div>
    );
};

export default Reports;