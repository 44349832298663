import React, {useEffect, useState} from 'react';
import './Users.css'
// import {periodDecrypt} from "../../../helpers/periodDecrypt";
import {observer} from "mobx-react-lite";
import FOUserService from "../../../services/FOUserService";
import UsersItem from "./UsersItem/UsersItem";
import Popup from "../../Popup/Popup";
import AddUserForm from "./AddUserForm/AddUserForm";
import EditUserForm from "./EditUserForm/EditUserForm";
export interface ISummary {
    first_name: string;
    last_name: string;
    full_name: string;
    key: string;
    email: string;
    email_confirmed: boolean;
    nickname: string;
    tg_nick: string | null;
}
const Users = () => {
    let total: any = 0
    const [isAddUserPopupOpen, setIsAddUserPopupOpen] = useState<boolean>(false)
    const [currentUser, setCurrentUser] = useState<ISummary | null>(null)
    const [isEditUserPopupOpen, setIsEditUserPopupOpen] = useState<boolean>(false)
    const [isSuccessfulPopupOpen, setIsSuccessFulPopupOpen] = useState<boolean>(false)
    const [newUserPassword, setNewUserPassword] = useState<string | undefined>('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [users, setUsers] = useState<ISummary[]>([])
    useEffect( () => {
        FOUserService.getUser().then(res => {
            setUsers(res)
            setIsLoading(false)
        })
    }, [])
    const customEditPopupSet = (value: boolean) => {
        setIsEditUserPopupOpen(value)
        if(!value) setCurrentUser(null)
    }
    const customAddPopupSet = (value: boolean) => {
        setIsAddUserPopupOpen(value)
        if(!value) setCurrentUser(null)
    }
    const addUserHandler = (data: {email: string, full_name: string, nickname: string, tg_nick: string}) => {
        setIsLoading(true);
        FOUserService.addUser(data).then(res => {
            FOUserService.getUser().then(res => {
                setIsAddUserPopupOpen(false);
                setCurrentUser(null)
                setUsers(res);
                setIsLoading(false);
            })
            setNewUserPassword(res.password)
            setIsSuccessFulPopupOpen(true)
        })
    }
    const editUserHandler = (data: {email: string, full_name: string, nickname: string, tg_nick: string}) => {
        setIsLoading(true);
        FOUserService.editUser(currentUser?.key, data).then(res => {
            FOUserService.getUser().then(res => {
                setCurrentUser(null)
                setUsers(res);
                setIsEditUserPopupOpen(false);
                setIsLoading(false);
            })
        })
    }
    return (
        <div className={"users__inner"}>
            <div className={"add-user__btn__inner"}>
                <button onClick={() => setIsAddUserPopupOpen(true)} className={"add-user__btn"}><p>Добавить пользователя</p></button>
            </div>
            <div className={"dashboard__container"}>
                <div className={"background-circle"} />
                <div className={"dashboard__top"}>
                    <p className={"period-name"}>ФИО</p>
                    <p className={"count-name"}>Никнейм артиста</p>
                    <p className={"sum-name"}>Email</p>
                </div>
                {
                    !isLoading ? <>
                        {
                            users.length <= 0 ? <div className={"no-reports"}><div>Здесь пока ничего нет <span>:(</span></div></div> : <div className={"dashboard__list"}>
                                <div className={"dashboard__list-wrapper"}>
                                    {
                                        users.map((item: ISummary, id) => {
                                            return <UsersItem setIsEditUserPopupOpen={setIsEditUserPopupOpen} setCurrentUser={setCurrentUser} key={id} item={item} />
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </> : <div className={"lds-ring__container"}>
                        <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                }
            </div>
            <Popup isActive={isAddUserPopupOpen} setActive={customAddPopupSet} >
                <AddUserForm isActive={isAddUserPopupOpen} setCurrentUser={setCurrentUser} addUserHandler={addUserHandler} setActive={customAddPopupSet} />
            </Popup>
            <Popup isActive={isEditUserPopupOpen} setActive={customEditPopupSet} >
                <EditUserForm setCurrentUser={setCurrentUser} editUserHandler={editUserHandler} user={currentUser} setActive={customEditPopupSet} />
            </Popup>
            <Popup isActive={isSuccessfulPopupOpen} setActive={setIsSuccessFulPopupOpen} >
                <div className={"successful-message__popup"}>
                    Вы успешно создали пользователя, пароль: {newUserPassword}
                </div>
            </Popup>
        </div>
    );
};

export default observer(Users);