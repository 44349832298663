import React, { MutableRefObject, Dispatch, SetStateAction, useEffect, useState } from 'react';
import docImg from "../../../../assets/img/doc.png";
import '../Reports.css'
import IndividualReportsService from "../../../../services/IndividualReportsService";
import profileIcon from '../../../../assets/img/profile-icon.svg'
import tgIcon from '../../../../assets/img/Telegram Icon White.png'
import { ISummary } from "../Reports";
import ReportPayoutAmountForm from './ReportPayoutAmountForm/ReportPayoutAmountForm';
const DashboardItem = (props: { item: ISummary, key: number, handleExternalEvent: Function }) => {
    const { item, handleExternalEvent } = props
    const [isFetching, setIsFetching] = useState<boolean>(false)
    const handleDownload = (key: string, period: string, individual: string) => {
        IndividualReportsService.download(key, period, individual)
    }
    const markSentOut = (key: string) => {
        IndividualReportsService.markSentOut(key).then(() => {
            console.log('report sent out');
            handleExternalEvent();
        })
    }

    return (

        <tr>
            <td className={"profile__icon-td"}>
                <img className={"document__icon_img"} src={docImg} alt="" onClick={() => handleDownload(item.key, item.period, item.full_name)} />
            </td>
            <td className={"reports-item__item"}>
                <div className={"reports-item__fio fio"}>{item.full_name}</div>
                <div className={"reports-item__fio nickname"}>{item.nickname}</div>
                <div className={"reports-item__email"}>{item.email}</div>
            </td>
            <td className={"reports-item__item"}>{item.period}</td>
            <td className={"reports-item__item"}>{Intl.NumberFormat(undefined, { style: 'currency', currency: 'RUB' }).format(parseFloat(item.period_profit))}</td>
            <td className={"reports-item__item"}>{item.profit}</td>
            <td className={"reports-item__item"}>
                {item.sent_out ?
                    <div className={"isSentOut sent reports-item__flag"}>
                        <span>Да</span>
                    </div> :
                    <div className={"isSentOut notsent reports-item__flag"} onClick={() => markSentOut(item.key)}>
                        <span>Нет</span>
                    </div>}
            </td>
            <td className={"reports-item__item"}>
                {item.approved_by_individual ?
                    <div className={"isApproved approved reports-item__flag"}>
                        <span>Да</span>
                    </div> :
                    <div className={"isApproved unapproved reports-item__flag"}>
                        <span>Нет</span>
                    </div>}
            </td>
            <td className={"reports-item__item"}>
                {item.payout_requested ?
                    <div className={"isApproved approved reports-item__flag"}>
                        <span>Да</span>
                    </div> :
                    <div className={"isApproved unapproved reports-item__flag"}>
                        <span>Нет</span>
                    </div>}
            </td>
            <td className={"reports-item__item"}>
                {item.paid_out ?
                    <div className={"isPaid paid reports-item__flag"}>
                        <span>Да</span>
                    </div> :
                    <ReportPayoutAmountForm
                        defaultPayoutAmount={item.profit}
                        reportItemKey={item.key}
                        handleExternalEvent={handleExternalEvent} />
                }
            </td>
            <td className={"reports-item__item send-in-tg-td"}><img src={tgIcon} alt="" /></td>
        </tr>
        // <div className={"dashboard__list-item"}>
        //     <div className={"list__item-doc__wrapper"}>
        //         <span onClick={() => handleDownload(item.key)} className={"list__item-doc"}>
        //             <img src={docImg} alt="" />
        //         </span>
        //     </div>
        //     <p className={"list__item-quarter"}>{item.period}</p>
        //     {/* <p className={"list__item-auditions"}>{item.number_of_plays}</p> */}
        //     <p className={"list__item-total"}>{item.profit}</p>
        //     <div className={"list__item-approvement-btn__wrapper"}>
        //         {item.approved_by_individual ?
        //             <button className={"list__item-approvement-btn approved"}>
        //                 <div>Согласован</div>
        //             </button>
        //             :
        //             <button onClick={() => approveReport(item.file_name)} className={isFetching ? "list__item-approvement-btn button--loading" : "list__item-approvement-btn"}>
        //                 <div className={"item-button__text"}>Согласовать</div>
        //             </button>
        //         }
        //     </div>
        //     {/*{*/}
        //     {/*    item.approved_by_individual ?             <div className={"list__item-approvement-btn__wrapper"}>*/}
        //     {/*        <button className={"list__item-approvement-btn approved"}>*/}
        //     {/*            Согласован*/}
        //     {/*        </button>*/}
        //     {/*    </div> :             <div className={"list__item-approvement-btn__wrapper"}>*/}
        //     {/*        <button onClick={() => approveReport(item.file_name)} className={"list__item-approvement-btn"}>*/}
        //     {/*            Согласовать*/}
        //     {/*        </button>*/}
        //     {/*    </div>*/}
        //     {/*}*/}
        //     <div className={"list__item-download-btn__wrapper"}>
        //         <button className={"list__item-download-btn"}
        //             onClick={() => handleDownload(item.key)}
        //         >Скачать</button>
        //     </div>
        // </div>
    );
};

export default DashboardItem;