import React, {useContext, useEffect} from 'react';
import './App.css';
import {Route, Routes, useNavigate} from "react-router-dom";
import Login from "./components/Login/Login";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import Users from "./components/Cabinet/Users/Users";
import Cabinet from "./components/Cabinet/Cabinet";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    const { store } = useContext(Context)
    const navigate = useNavigate()
    const fetchUser = async () => {
        return await store.checkAuth()
    }
    useEffect(() => {
        fetchUser().then(res => {
            if (!res) {
                navigate('/login')
            }
        })
    }, [store.isAuth])
    if (store.isFetching) return <div className={"lds-ring__container app_"}>
        <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    return (
        <div className="App">
            <ToastContainer />
            <div className={"background"}>
                <Routes>
                    <Route path={"/"} element={<Login />} />
                    <Route path={"login"} element={<Login />} />
                    <Route path={"users"} element={<Users />} />
                    <Route path={"cabinet/*"} element={<Cabinet />} />
                    <Route path={"*"} element={<Cabinet />} />
                </Routes>
            </div>
        </div>
    );
}

export default observer(App);
