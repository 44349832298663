import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import './EditUserForm.css'
import {ISummary} from "../Users";

type EditUserFormValues = {
    full_name: string;
    nickname: string;
    email: string;
    tg_nick: string;
}

const editUserSchema = yup.object().shape({
    full_name: yup.string().required('Введите ФИО артиста'),
    nickname: yup.string().required('Введите никнейм артиста'),
    tg_nick: yup.string(), //.required('Введите свой никнейм в telegram'),
    email: yup.string().email('Не валидная почта').required('Введите почту артиста')
});

const EditUserForm = (props: {setActive: (value: boolean) => void, user: ISummary | null, editUserHandler: Dispatch<SetStateAction<any>>, setCurrentUser: Dispatch<SetStateAction<any>>}) => {
    const { register, setValue ,handleSubmit, formState: { errors } } = useForm<EditUserFormValues>({resolver: yupResolver(editUserSchema)});
    const {setActive, user, editUserHandler, setCurrentUser} = props
    const onSubmit: SubmitHandler<EditUserFormValues> = data => {
        editUserHandler(data)
    }
    if(user?.full_name) setValue("full_name", user.full_name)
    else setValue("full_name", "")
    if(user?.nickname) setValue("nickname", user.nickname)
    else setValue("nickname", "")
    if(user?.email) setValue("email", user.email)
    else setValue("email", "")
    if(user?.tg_nick) setValue("tg_nick", user.tg_nick)
    else setValue("tg_nick", "")
    return (
        <div className={"edit-user__form__container"}>
            <h3 className={"edit-user__form-title"}>⚡️ Редактировать Пользователя</h3>
            <form className={"edit-user__form"} onSubmit={handleSubmit(onSubmit)}>
                <label className={"edit-user__form-label"}>Фамилия Имя Отчество</label>
                <input className={"edit-user__form-input"} {...register("full_name")} type="text"/>
                <label className={"edit-user__form-label"}>Никнейм Артиста</label>
                <input className={"edit-user__form-input"} {...register("nickname")} type="text"/>
                <label className={"edit-user__form-label"}>Email</label>
                <input className={"edit-user__form-input"} {...register("email")} type="text"/>
                <label className={"add-user__form-label"}>Telegram ник <span>(для отправки оповещений)</span></label>
                <input className={"add-user__form-input"} {...register("tg_nick")} type="text"/>
                <div className={"edit-user__form-buttons"}>
                    <button onClick={() => {
                        setActive(false)
                    }} className={"edit-user__form-cancel__btn"}>ОТМЕНА</button>
                    <button type={"submit"} className={"edit-user__form-cancel__btn"}>СОХРАНИТЬ</button>
                </div>
            </form>
        </div>
    );
};

export default EditUserForm;