import {authHeaders} from "../http/AuthHeaders";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;
export class AuthService {
    static async login(username: string, password: string): Promise<any> {
        try {
            const response = await fetch(`${API_URL}login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password })
            });
            return response.json()
        } catch (error) {
            console.log('error')
        }
    }
    static async checkAuth(): Promise<number> {
        try {
            const response = await fetch(`${API_URL}musictrack?page_num=1&items_per_page=1`, {
                method: 'GET',
                headers: authHeaders()
            });
            return 1
        } catch (error) {
            return 0
        }
    }
    static async logout(): Promise<void> {
        localStorage.removeItem('user')
    }

    static async telegramLogin(telegramUser: any): Promise<any> {
        try {
            const response = await fetch(`${API_URL}telegram-login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(telegramUser)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            console.error('Error during Telegram login:', error);
            throw error;
        }
    }
}