import React, { useEffect, useRef } from 'react';
import { isatty } from 'tty';
import { ReferenceEntry } from 'typescript';
import './Popup.css'
const Popup = (props: { isActive: boolean, setActive: (value: boolean) => void, children: React.ReactNode }) => {
    const { isActive, setActive, children } = props
    const modalRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        function handleOutsideClick(event: any) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                // Clicked outside the modal
                setActive(false); // close the modal
            }
        }

        if (isActive) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isActive]);

    return (
        <div className={isActive ? 'modal' + ' active' : 'modal'}>
            <div className={'modal_content'} onClick={e => e.stopPropagation()} ref={modalRef}>
                <div onClick={() => setActive(false)} className={"cross-close"}></div>
                {children}
            </div>
        </div>
    );
};
export default Popup;