import { authHeaders } from "../http/AuthHeaders";
const API_URL = process.env.REACT_APP_BACKEND_API_URL;
class MoneyFlowService {
    static async getMoneyFlowItems(pageNumber: number, itemsPerPage: number, recordType: string, debitType: string, searchText: string) {
        return fetch(`${API_URL}moneyflow?page_num=${pageNumber}&items_per_page=${itemsPerPage}&record_type=${recordType}&debit_type=${debitType}&search_text=${searchText}`, {
            method: 'GET',
            headers: authHeaders()
        })
            .then(res => res.json())
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    static async addSpending(upc: string, artist: string, album: string, category: string, description: string,
        amount: number, date_added: Date) {
        return fetch(`${API_URL}moneyflow`, {
            method: 'POST',
            headers: authHeaders(),
            body: JSON.stringify({
                upc: upc,
                artist: artist,
                album: album,
                category: category,
                description: description,
                amount: amount,
                date_added: date_added
            })
        })
            .then(res => {
                if (res.status < 300) {
                    return res.json();
                }
                console.warn("Something went wrong")
            })
            .catch((error) => {
                console.error('Error:', error);
            })
    }
}

export default MoneyFlowService