import React, { useState } from 'react';
import './AddReleaseForm.css'
import SingleStepForm from './SingleStepForm/SingleStepForm';
const AddReleaseForm = (props: { setActive: (value: boolean) => void, handleExternalEvent: Function, musicTrack?: any }) => {
    const { setActive, handleExternalEvent, musicTrack } = props
    return (
        <div className={"add-release__form__inner"}>
            <div className={"add-release__popup-info"}>
                <h1 className={"add-release__form__title"}><span>💿</span>Добавить релиз в каталог</h1>
            </div>
            <SingleStepForm setActive={setActive} handleExternalEvent={handleExternalEvent} musicTrack={musicTrack}/>
        </div>
    );
};

export default AddReleaseForm;
